import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AppConfigServiceService } from 'src/app/services/appconfig/app-config-service.service';


@Component({
  selector: 'rd-topology',
  templateUrl: './topology.component.html',
  styleUrls: ['./topology.component.scss']
})
export class TopologyComponent implements OnInit {
  iFrame2:any = null;

  constructor(private environment:AppConfigServiceService,public sanitizer: DomSanitizer) { }

  ngOnInit(): void {

  this.environment.getTopology().subscribe(response=>{
      // console.log("API Responce",response.iframe)
      
      const url = JSON.parse(response)

      // this.iFrame=url.iframe;
      console.log(this.iFrame2)
      // this.iFrame=url.iframe;
      this.iFrame2 = this.sanitizer.bypassSecurityTrustResourceUrl(url.grafana);
      console.log(this.iFrame2,"grafana")
    })
  }

}




