<!--
  ========================LICENSE_START=================================
  O-RAN-SC
  %%
  Copyright (C) 2019 AT&T Intellectual Property
  %%
  Licensed under the Apache License, Version 2.0 (the "License");
  you may not use this file except in compliance with the License.
  You may obtain a copy of the License at

       http://www.apache.org/licenses/LICENSE-2.0

  Unless required by applicable law or agreed to in writing, software
  distributed under the License is distributed on an "AS IS" BASIS,
  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
  See the License for the specific language governing permissions and
  limitations under the License.
  ========================LICENSE_END===================================
  -->
<!-- Slide Menu-->
<mat-drawer-container autosize [hasBackdrop]="false">
  <mat-drawer
    mode="side"
    opened
    class="sidebarMenu"
    [ngClass]="{ sidemenutoggle: isToggled }"
  >
    <section
      class="menu-header"
      [ngClass]="{ 'menu-header__dark': darkModeActive }"
    >
      <img src="../../assets/logo.png" class="vvdn-logo" alt="" />
      <img src="../../assets/icon.png" class="vvdn-icon" alt="" />
    </section>
    <section #sidenav class="menu-body">
      <rd-sidenav-list></rd-sidenav-list>
    </section>
    <div class="logout" (click)="logout()">
      <span class="icon material-symbols-outlined">logout</span>
      <span> Logout</span>
    </div>
  </mat-drawer>

  <mat-drawer-content class="root__container">
    <header
      [ngClass]="{ 'main__header-dark': darkModeActive }"
      class="main__header"
    >
      <div class="header-left">
        <span class="material-symbols-outlined icon-toggle" (click)="toggleClass()">
          segment
        </span>
        <strong>{{ message }}</strong>
      </div>

      <div class="toggle-right">
        Dark Mode
        <mat-slide-toggle
          color="primary"
          [(ngModel)]="lightTheme"
          (change)="toggle()"
        ></mat-slide-toggle>
        Light Mode
      </div>
      <div class="header-right">
        <div class="date-time">
          <span
            >{{ current_date | date : "dd" }} {{ month }}
            {{ current_date | date : "yyyy" }}</span
          >
          <strong>{{ current_date | date : "hh:mm a" }}</strong>
        </div>
        <div class="notification">
          <button mat-mini-fab>
            <span class="material-symbols-outlined"> notifications </span>
            <span class="notiCircle"></span></button>
        </div>


        



        <div class="user-detail">
          <div class="user-pic">
            <img src="assets/images/profile.jpg" alt="" />
          </div>
        </div>
      </div>
    </header>

    <!-- Main Content -->
    <main
      class="main__container"
      [ngClass]="{ 'main-container__bg-dark': darkModeActive }"
    >
      <div
        class="main__container__body"
        [ngClass]="{ 'dark-theme': darkModeActive }"
      >
        <div class="main-container__bg"></div>
        <router-outlet></router-outlet>
      </div>

      <!-- Footer -->
      <footer
        class="main__footer"
        [ngClass]="{ 'main-footer__bg-dark': darkModeActive }"
      >
        <div class="main-footer-licence">
          <rd-footer></rd-footer>
        </div>
        <!-- <button mat-flat-button  color="primary" class="main-footer-instance">
                <span>{{selectedInstanceName}} </span> -->
        <!-- </button> -->
      </footer>
    </main>
  </mat-drawer-content>
</mat-drawer-container>
