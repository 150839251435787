/*-
 * ========================LICENSE_START=================================
 * O-RAN-SC
 * %%
 * Copyright (C) 2019 AT&T Intellectual Property
 * %%
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * ========================LICENSE_END===================================
 */

import { HttpClient, HttpResponse,HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthGuardServiceService } from '../auth-guard/auth-guard-service.service';
import { AppConfigServiceService } from '../appconfig/app-config-service.service';
@Injectable({
  providedIn: 'root'
})
export class XappOnboarderService {
  private apiUrl = 'http://localhost:4000';
  data:object={};
  token:any;
  

  //subject 

  private chartDataSubject = new BehaviorSubject<any>([]);
  chartData$ = this.chartDataSubject.asObservable();
  private postDataSubject = new BehaviorSubject<any>(null);
  postData$ = this.postDataSubject.asObservable();


  //automatically refresh the chart list 
  private _refreshrequired=new Subject<void>();
  get Refreshrequired(){
   return this._refreshrequired;

  }

  updateChartData(newData: any) {
    this.chartDataSubject.next(newData);
  }


  updatePostData(data: any) {
    this.postDataSubject.next(data);
  }

  private component = 'xappobrd';
  private appConfig;
  servicesBaseUrl:any;

  constructor(
    
    private httpClient: HttpClient,
    private http: HttpClient,
    public authService: AuthGuardServiceService,
    private environment:AppConfigServiceService,
    private injector: Injector
  ) {}



  
  headers = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Authorization" :localStorage.getItem('id_token')  
    }),
  };
  

  login(id:any ): Observable<any> {
    return this.http.post("http://35.238.94.32:32080/e2mgr/authenticate/login",id,{observe: 'response' });
  }

  onboardXappFile(data:any):Observable<any>{
    console.log(data,"data");
    return this.httpClient.post(environment.dynamicUrl + '/onboard/api/v1/register',data).pipe(
      tap(()=>{
        this.Refreshrequired.next();
      })
    );;
  }

getonboardXappFile():Observable<any>{
  return this.httpClient.get(environment.dynamicUrl+'/onboard/api/v1/charts')
}

deployXappFile(data:any):Observable<any>{
  return this.httpClient.post(environment.dynamicUrl+'/appmgr/ric/v1/xapps',data);
}

deleteXappFile(data:any):Observable<any>{
  console.log(data,"delete");
  return this.httpClient.delete(environment.dynamicUrl+'/delete/api/charts/'+ data.xappName+"/"+data.HelmVersion).pipe(
    tap(()=>{
      this.Refreshrequired.next();
    })  
  );;;
}

undeployXappFile(data:any):Observable<any>{
  console.log(data);

  const headers = new HttpHeaders({
    'Content-Type': 'application/json',
  });

  const options = {
    headers,
    body: data    
  };
  
  return this.httpClient.delete(environment.dynamicUrl+'/onboard/api/v1/unregister',options).pipe(
    tap(()=>{
      this.Refreshrequired.next();
    })
  );;;

}

controlChartXappFile():Observable<any>{
  return this.httpClient.get(environment.dynamicUrl+'/backend/backend/api/pods');
}

getAlarmDefinitions():Observable<any>{
  return this.httpClient.get(environment.dynamicUrl+'/alarm/ric/v1/alarms/define');
}


getAlarm():Observable<any>{
  return this.httpClient.get(environment.dynamicUrl+'/alarm/ric/v1/alarms/active');
}

getGnb():Observable<any>{
  return this.httpClient.get(environment.dynamicUrl+'/e2mgr/v1/nodeb/states');
  }

getUe():Observable<any>{
  return this.httpClient.get(environment.dynamicUrl+'/backend/ric/v1/api/uelist');
} 

getCell():Observable<any>{
  return this.httpClient.get(environment.dynamicUrl+'/backend/ric/v1/api/celllist');
} 

getInventoryName(res:any):Observable<any>{
  return this.httpClient.get(environment.dynamicUrl+'/backend/kpi'+"/"+res);
  }


  // getKpmData(): Observable<any> {
  //   return this.http.get(`${this.apiUrl}/gnb_001_001_0000001du`);
  // }
  getKpmData(id : string):Observable<any>{
    // return this.httpClient.get(environment.apiurl+'/api/kpi'+'/'+'gnb_123_123_000000d0');
    return this.httpClient.get(environment.dynamicUrl+'/api/kpi'+'/'+ id);

  }

  getSubscriptionData(): Observable<any> {
    console.log('Fetching data from:', this.getSubscriptionData); 
   return this.httpClient.get(environment.dynamicUrl+'/backend/apis/subscription');
  }
  
 
}