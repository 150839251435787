import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { RicInstance } from '../interfaces/dashboard.types';
import { RicRegion } from '../interfaces/dashboard.types';
import { InstanceSelectorService } from '../services/instance-selector/instance-selector.service';
import { UiService } from '../services/ui/ui.service';
import { Route, Router } from '@angular/router';

import { E2ManagerService } from '../services/e2-mgr/e2-mgr.service';

@Component({
  selector: 'rd-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  message!:string;
  showMenu = false;
  darkModeActive: boolean;
  selectedInstanceName: string = 'Select RIC instance';
  private instanceChange: Subscription;
  subscription!: Subscription;
  data: any;
  lightTheme: boolean= true;
  
  isToggled: boolean = false;

  toggleClass(): void {
    this.isToggled = !this.isToggled;
  }
  // get lightTheme(): boolean {
  //   return document.documentElement.getAttribute('theme') === 'light';
  // }

  toggle() {
    if (this.lightTheme) {
      if(document.body.classList.contains('dark-theme')){
        document.body.classList.remove('dark-theme');
      }
    } else {
      if(!document.body.classList.contains('dark-theme')){

      document.body.classList.add('dark-theme');
      }
    }
  }


  constructor(
    public ui: UiService,
    private instanceSelectorService: InstanceSelectorService,
    private router:Router,
    private e2manager: E2ManagerService) {
      this.subscription = this.instanceSelectorService.currentMessage.subscribe(message => this.message = message);

  }
  current_date = new Date();
  month = this.current_date.toLocaleString('default', { month: 'long' });

  ngOnInit() {
    this.subscription = this.instanceSelectorService.currentMessage.subscribe(message => this.message = message);
    // this.instanceSelectorService.currentMessage()
    this.ui.darkModeState.subscribe((value) => {
      this.darkModeActive = value;
      

    }
    );

    this.instanceChange = this.instanceSelectorService.getSelectedInstance().subscribe((instance: RicInstance) => {
      if (instance.name) {
        this.selectedInstanceName = instance.name;
      } else {
        this.instanceSelectorService.getAllInstances().subscribe((regArray: RicRegion[]) => {
          this.instanceSelectorService.updateSelectedInstance(regArray[0].instances[0]);
        });
      }
    });

	setInterval(() => {
		this.current_date = new Date();
	}, 1000);

  }

  ngOnDestroy() {
    this.instanceChange.unsubscribe();
    this.subscription.unsubscribe();
  }

  modeToggleSwitch() {
    this.ui.darkModeState.next(!this.darkModeActive);
  }

  logout(){
    this.e2manager.getlogout().subscribe(()=>{
      this.router.navigate(['/login']);
      localStorage.removeItem("id_token");
    })
    
  


}

refreshPage(): void {
  // Reload the entire page
  window.location.reload();
}

}



