import { Component, OnInit } from '@angular/core';
import { XappOnboarderService } from '../services/xapp-onboarder/xapp-onboarder.service';
import { ActivatedRoute } from '@angular/router';

interface KpmDetail {
  ranfuncId: string;
  ranfuncOid: string;
  ranfuncDef: string;
  shortName: string;
  oid: string;
  description: string;
  eventTriggerStyleName: string;
  reportStylistName: string;
  headerFormat: string;
  messageFormat: string;
  ranFuncDescription:string;
  measurement:string;
}

interface RcDetail {
  ranfuncId: string;
  ranfuncOid: string;
  ranfuncDef: string;
  differentKey1: string;
  differentKey2: string;
  anotherDescription: string;
  ranFuncDescription:string;
}

interface CccDetail {
  ranfuncId: string;
  ranfuncOid: string;
  ranfuncDef: string;
  additionalKey1: string;
  additionalKey2: string;
  anotherDescription: string;
  ranFuncDescription:string;
}

@Component({
  selector: 'rd-gnb-information',
  templateUrl: './gnb-information.component.html',
  styleUrls: ['./gnb-information.component.scss']
})
export class GnbInformationComponent implements OnInit {
  selectedDetail: string = 'KPM'; // Default selected detail
  details: any;
  inventory: any;
  objectKeys = Object.keys;
  


  constructor(
    private xappOnboarderService: XappOnboarderService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    if(this.route.snapshot?.params['id']) {
      this.fetchDetails(this.route.snapshot?.params['id']);
    }
  
  }

  

  // fetchDetails(id: string, detail: string) {
  //   this.selectedDetail = detail;
  //   if (detail === 'KPM') {
  //     this.xappOnboarderService.getKpmData(id).subscribe(data => {
  //       this.details = JSON.parse(data) ;
  //       this.inventory = this.details[0][Object.keys(this.details[0])[0]];
  //       console.log("details checking--1", this.details[0][Object.keys(this.details[0])[0]]);
  //       // this.details[0].kpm[Object.keys(this.details[0])[0]]

  //       const kpmData = this.inventory.kpm;

  //     // Extract values
  //     const meas=kpmData.measurement;
  //     const measurement=Object.values((meas))
  //   //  console.log( JSON.stringify(measurement).split(','));
  //    console.log( Object.values((meas)));
  //     const eventTriggerStyleName = kpmData.eventTriggerStyleName;
  //     const reportStyleName = kpmData.reportStyleName;
  //     const indicationHeaderFormat = kpmData.indicationHeaderFormat;
  //     const indicationMessageFormat = kpmData.indicationMessageFormat;
      

  //     console.log(measurement, "measurement");
  //     console.log(eventTriggerStyleName, "eventTriggerStyleName");
  //     console.log(reportStyleName, "reportStyleName");
  //     console.log(indicationHeaderFormat, "indicationHeaderFormat");
  //     console.log(indicationMessageFormat, "indicationMessageFormat");

  //     });
  //   }
  // }

  fetchDetails(id: string) {
    this.xappOnboarderService.getKpmData(id).subscribe(data => {
      this.details = JSON.parse(data);
      this.inventory = this.details[0][Object.keys(this.details[0])[0]];
      console.log("details checking--1", this.details[0][Object.keys(this.details[0])[0]]);
    });
  }
  hasData(detail: string): boolean {
    if (this.inventory && this.inventory[detail.toLowerCase()]) {
      const detailData = this.inventory[detail.toLowerCase()];
      return detailData.ranFuncID !== 0;
    }
    return false;
  }


  onSelect(detail: string) {
    console.log('Selected detail:', detail);
    this.selectedDetail = detail;
    console.log('Current selected details:', this.details[this.selectedDetail]); // Debug statement
    // this.fetchDetails(this.route.snapshot?.params['id'], detail); 

  }


}
