<!--
  ========================LICENSE_START=================================
  O-RAN-SC
  %%
  Copyright (C) 2019 AT&T Intellectual Property
  %%
  Licensed under the Apache License, Version 2.0 (the "License");
  you may not use this file except in compliance with the License.
  You may obtain a copy of the License at

       http://www.apache.org/licenses/LICENSE-2.0

  Unless required by applicable law or agreed to in writing, software
  distributed under the License is distributed on an "AS IS" BASIS,
  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
  See the License for the specific language governing permissions and
  limitations under the License.
  ========================LICENSE_END===================================
  -->

<!-- browse icons at https://material.io/tools/icons/?style=baseline -->
<div class="menuNav">
  <mat-nav-list [ngClass]="{ dark: darkMode }">
    <a
      mat-list-item
      routerLinkActive="active"
      routerLink="/dashboard"
      (click)="onClickdata('Dashboard')"
    >
    <span class="icon material-symbols-outlined">
      insert_chart
      </span>
      <span class="nav-caption">Dashboard</span>
    </a>
    <!-- <a mat-list-item routerLinkActive="active" routerLink="/catalog" (click)="onClickdata('Catalog')">
		<mat-icon>list</mat-icon> <span class="nav-caption">Catalog</span>
	</a> -->
    <a
      mat-list-item
      routerLinkActive="active"
      routerLink="/control"
      (click)="onClickdata('Xapps')"
    >
    <span class="icon material-symbols-outlined">grid_view</span>
      <!-- <span class="icon material-symbols-outlined">action_key</span> -->
      <span class="nav-caption">Xapps</span>
    </a>
    <a
      mat-list-item
      routerLinkActive="active"
      routerLink="/alarm"
      (click)="onClickdata('Alarm')"
    >
      <span class="icon material-symbols-outlined">alarm_on</span>
      <span class="nav-caption">Alarm</span>
    </a>
    <a
      mat-list-item
      routerLinkActive="active"
      routerLink="/gnblist"
      (click)="onClickdata('gNodeB List')">
      <span class="icon material-symbols-outlined">event_list</span>
      <span class="nav-caption">gNodeB List</span>
    </a>

    <a
    mat-list-item
    routerLinkActive="active"
    routerLink="/subscription"
    (click)="onClickdata('Subscription')">
    <span class="icon material-symbols-outlined">subscriptions</span>
    <span class="nav-caption">Subscription</span>
  </a>

  <a
  mat-list-item
  routerLinkActive="active"
  routerLink="/ricinfo"
  (click)="onClickdata('RIC Info')">
  <span class="icon material-symbols-outlined">perm_device_information</span>
  <span class="nav-caption">Ric Info</span>
</a>





    <!-- <a
    mat-list-item
    routerLinkActive="active"
    routerLink="/gnbinfo"
    (click)="onClickdata('gnbinfo')"
  >
    <span class="icon material-symbols-outlined">event_list</span>
    <span class="nav-caption">GnbInfo</span>
  </a> -->





    <a
      mat-list-item
      routerLinkActive="active"
      routerLink="/topology"
      (click)="onClickdata('Topology')"
    >
    <span class="icon material-symbols-outlined">
      network_node
      </span>
      <span class="nav-caption">Topology</span>
    </a>

    <a
      mat-list-item
      routerLinkActive="active"
      routerLink="/ue"
      (click)="onClickdata('UE')"
    >
    <span class="icon material-symbols-outlined">
      phonelink_ring
      </span>
      <span class="nav-caption">UE </span>
    </a>

    <a
      mat-list-item
      routerLinkActive="active"
      routerLink="/cell"
      (click)="onClickdata('Cell')"
    >
      <span class="icon material-symbols-outlined">signal_cellular_alt</span>
      <span class="nav-caption">Cell </span>
    </a>
  </mat-nav-list>
</div>
<!-- <div class="siteInfo">
  <span>NEAR RT-RIC</span>
  5G Cloud Management System
</div> -->
