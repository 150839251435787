import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs-compat';
import { environment } from 'src/environments/environment';
import{environmentprod} from 'src/environments/environment.prod'


@Injectable({
  providedIn: 'root'
})
export class AppConfigServiceService {

  private appConfig;
  private config2: any;

    constructor (private injector: Injector,  private http: HttpClient,) { }

    loadAppConfig() {
      let http = this.injector.get(HttpClient);

      return http.get('/assets/mnt/config-file.json')
      .toPromise()
      .then(data => {
          
          let data2 = JSON.parse(data.toString());
          this.appConfig = data2.servicesBaseUrl;
          console.log(this.appConfig,"hii");
        
          environment.dynamicUrl = this.appConfig;
          environmentprod.dynamicUrl=this.appConfig
      })
    }
    
    get config() {
        return this.appConfig;
    }

    getConfig(): Observable<any> {
      return this.http.get('/assets/mnt/config-file.json');
    }

    getTopology(): Observable<any> {
      return this.http.get('/assets/mnt/config-file.json');
    }
    
}



