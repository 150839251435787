import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { GnbInformationComponent } from 'src/app/gnb-information/gnb-information.component';
import { XappOnboarderService } from 'src/app/services/xapp-onboarder/xapp-onboarder.service';

@Component({
  selector: 'rd-onboard-subscription',
  templateUrl: './onboard-subscription.component.html',
  styleUrls: ['./onboard-subscription.component.scss']
})
export class OnboardSubscriptionComponent implements OnInit {
  displayedColumns: string[] = ['Inventory_name'];
  dataSource!: MatTableDataSource<any>;

  constructor(public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: { items: string[] },
    private cdr: ChangeDetectorRef,  
    private xappOnboarderService: XappOnboarderService,
    private changeDetectorRef :ChangeDetectorRef,
    private router: Router,
    public dialogRef: MatDialogRef<OnboardSubscriptionComponent>,
) { }

ngOnInit() {
  this.getGnb();
  // this.xappOnboarderService.Refreshrequired.subscribe(Response=>{
  //   this.getGnb();
  //   this.updateSubscription = interval(1000).subscribe(() => {
  //     this.getGnb();
  //   //  this.getInventoryName(inventory)
  //   });
    
  // })
  // this.getGnb();
  // this.ui.darkModeState.subscribe((isDark) => {
  //   this.darkMode = isDark;
  // });

  // this.instanceChange = this.instanceSelectorService.getSelectedInstance().subscribe((instance: RicInstance) => {
  //   if (instance.key) {
  //     this.instanceKey = instance.key;
  //   }
  // });
}

  getGnb() {
    this.xappOnboarderService.getGnb().subscribe({
      next: (res) => {
        
        let data = JSON.parse(res);
        let Original = Object.entries(data);
        let mainArray: any = [];

        for (let i = 0; i < Original.length; i++) {
          mainArray.push(Original[i][1])

        }
        const newData = mainArray.flat();
        this.dataSource = new MatTableDataSource(newData);
        console.log( this.dataSource);
        
        // this.dataSource.sort = this.sort;
        this.changeDetectorRef.detectChanges();
      },
      error: console.log,

    })
    
  }

  onClick(name: string, route: string) {
    console.log(name)
    this.closeDialog();
    this.router.navigate([route, name]);

  }
  closeDialog(): void {
    console.log(this.closeDialog,"closedialog")
    this.dialogRef.close();
  }
  
}
