

<div class="ranfuncbox">
  <div class="ranfunccol active" [class.active]="selectedDetail === 'KPM'" (click)="onSelect('KPM')"   >
    <span class="circle">KPM</span>

  </div>
  <div class="ranfunccol" (click)="onSelect('RC')"  [class.active]="selectedDetail === 'RC'"  >
    <span class="circle rcInformation">RC</span>

  </div>
  <div class="ranfunccol" [class.active]="selectedDetail === 'CCC'"   (click)="onSelect('CCC')"  >
    <span class="circle">CCC</span>

  </div>
</div>
<div class="tabgrid">
  <div class="tabgrid-col">
    <div class="title">
      <!-- {{ selectedDetail.ranFuncDescription }} (Ransum Def.: 001.001.001) -->
    </div>

    <!-- KPM------------------ -->
    <!-- KPM------------------ -->
<div class="tabbody" *ngIf="selectedDetail == 'KPM'">
  <ul *ngIf="selectedDetail === 'KPM' && inventory?.kpm">
    <li><strong>Ranfunc ID</strong>: {{ inventory['kpm']?.ranFuncID }}</li>
    <li><strong>Ranfunc OID</strong>: {{ inventory['kpm']?.ranFuncOID }}</li>
    <li><strong>Ranfunc Name</strong>: {{ inventory['kpm']?.ranFunctionName }}</li>
    <li><strong>Ranfunc Short Name</strong>: {{ inventory['kpm']?.ranFuncShortName }}</li>
    <li><strong>Ranfunc Description</strong>: {{ inventory['kpm']?.ranFuncDescription }}</li>
    <!-- <li><strong>Measurement ID</strong>: {{ inventory['kpm']?.measurement}}</li> -->

    <li><strong>Event Trigger Styles</strong>:
      <ul *ngIf="inventory['kpm']?.eventTriggerStyleName">
        <li *ngFor="let style of inventory['kpm']?.eventTriggerStyleName">{{ style }}</li>
      </ul>
    </li>
    <li><strong>Report Styles</strong>:
      <ul *ngIf="inventory['kpm']?.reportStyleName">
        <li *ngFor="let style of inventory['kpm']?.reportStyleName">{{ style }}</li>
      </ul>
    </li>
    <li><strong>Indication Header Formats</strong>:
      <ul *ngIf="inventory['kpm']?.indicationHeaderFormat">
        <li *ngFor="let format of inventory['kpm']?.indicationHeaderFormat">{{ format }}</li>
      </ul>
    </li>
    <li><strong>Indication Message Formats</strong>:
      <ul *ngIf="inventory['kpm']?.indicationMessageFormat">
        <li *ngFor="let format of inventory['kpm']?.indicationMessageFormat">{{ format }}</li>
      </ul>
    </li>
    
    <li><strong >Measurements</strong>:
      <div class="measurements_container">
      <ul  *ngIf="inventory['kpm']?.measurement">
        <li *ngFor="let key of objectKeys(inventory['kpm']?.measurement)">
          {{ key }}: {{ inventory['kpm']?.measurement[key] }}
        </li>
      </ul>
    </div>
    </li>
  </ul>
</div>

    <!-- CCC------------------------- -->
    <div class="tabbody" *ngIf="selectedDetail == 'CCC'">


      <ul *ngIf="selectedDetail === 'CCC' && inventory?.ccc">
        <li><strong>Ranfunc ID</strong>: {{ inventory['ccc']?.ranFuncID }}</li>
        <li><strong>Ranfunc OID</strong>: {{ inventory['ccc']?.ranFuncOID }}</li>
        <li><strong>Ranfunc Name</strong>: {{ inventory['ccc']?.ranFunctionName }}</li>
        <li><strong>Ranfunc Short Name</strong>: {{ inventory['ccc']?.ranFuncShortName }}</li>
        <li><strong>Ranfunc Description</strong>: {{ inventory['ccc']?.ranFuncDescription }}</li>
        <li><strong>Event Trigger Styles</strong>:

          <ul *ngIf="inventory['ccc']?.eventTriggerStyleName">
            <li *ngFor="let style of inventory['ccc']?.eventTriggerStyleName">{{ style }}</li>
          </ul>
        </li>

        <li><strong>Report Styles</strong>:
          <ul *ngIf="inventory['ccc']?.reportStyleName">
            <li *ngFor="let style of inventory['ccc']?.reportStyleName">{{ style }}</li>
          </ul>
        </li>

        <li><strong>Indication Header Formats</strong>:
          <ul *ngIf="inventory['ccc']?.indicationHeaderFormat">
            <li *ngFor="let format of inventory['ccc']?.indicationHeaderFormat">{{ format }}</li>
          </ul>
        </li>
        <li><strong>Indication Message Formats</strong>:
          <ul *ngIf="inventory['ccc']?.indicationMessageFormat">
            <li *ngFor="let format of inventory['ccc']?.indicationMessageFormat">{{ format }}</li>
          </ul>
        </li>

        <!-- <li><strong>Report Styles</strong>:
          <ul>
            <li *ngFor="let style of details.CCC['ReportStyle-Name']">{{ style }}</li>
          </ul>
        </li> -->
        <!-- <li><strong>Indication Header Formats</strong>:
          <ul>
            <li *ngFor="let format of details.CCC.IndicationHeaderFormat">{{ format }}</li>
          </ul>
        </li>
        <li><strong>Indication Message Formats</strong>:
          <ul>
            <li *ngFor="let format of details.CCC.IndicationMessageFormat">{{ format }}</li>
          </ul>
        </li> -->
      </ul>
    </div>

    <!-- RC---------------- -->
    <div class="tabbody" *ngIf="selectedDetail == 'RC'">
      <ul *ngIf="selectedDetail === 'RC' && inventory?.rc">
        <li><strong>Ranfunc ID</strong>: {{ inventory['rc']?.ranFuncID |json }}</li>
        
        <li><strong>Ranfunc OID</strong>: {{ inventory['rc']?.ranFuncOID }}</li>
        <li><strong>Ranfunc Name</strong>: {{ inventory['rc']?.ranFunctionName }}</li>
        <li><strong>Ranfunc Short Name</strong>: {{ inventory['rc']?.ranFuncShortName }}</li>
        <li><strong>Ranfunc Description</strong>: {{ inventory['rc']?.ranFuncDescription }}</li>
        <li><strong>Event Trigger Styles</strong>:
          <ul *ngIf="inventory['rc']?.eventTriggerStyleName">
            <li *ngFor="let style of inventory['rc']?.eventTriggerStyleName">{{ style }}</li>
          </ul>
        </li>
        <li><strong>Report Styles</strong>:
          <ul *ngIf="inventory['rc']?.reportStyleName">
            <li *ngFor="let style of inventory['rc']?.reportStyleName">{{ style }}</li>
          </ul>
        </li>
        <li><strong>Indication Header Formats</strong>:
          <ul *ngIf="inventory['rc']?.indicationHeaderFormat">
            <li *ngFor="let format of inventory['rc']?.indicationHeaderFormat">{{ format }}</li>
          </ul>
        </li>
        <li><strong>Indication Message Formats</strong>:
          <ul *ngIf="inventory['rc']?.indicationMessageFormat">
            <li *ngFor="let format of inventory['rc']?.indicationMessageFormat">{{ format }}</li>
          </ul>
        </li>
      </ul>
    </div>
    
  </div>



  <!-- <ul>
        <li><span>Ranfunc ID</span><span>:</span><span>{{ details[selectedDetail].ranfuncId }}</span></li>
        <li><span>Ranfunc OID</span><span>:</span><span>{{ details[selectedDetail].ranfuncOid }}</span></li>
        <li><span>Ranfunc Def.</span><span>:</span><span>{{ details[selectedDetail].ranfuncDef }}</span></li>
      </ul>
      <ul>
        <li><strong>Ranfunc Name</strong></li>
        <li><span>Short Name</span><span>:</span><span>{{ details[selectedDetail].shortName }}</span></li>
        <li><span>OID</span><span>:</span><span>{{ details[selectedDetail].oid }}</span></li>
        <li><span>Description</span><span>:</span><span>{{ details[selectedDetail].description }}</span></li>
      </ul>
      <ul>
        <li><strong>Event Trigger Stylist</strong></li>
        <li><span>Style Name</span><span>:</span><span>{{ details[selectedDetail].eventTriggerStyleName }}</span></li>
      </ul>
      <ul>
        <li><strong>Report Stylist</strong></li>
        <li><span>Name</span><span>:</span><span>{{ details[selectedDetail].reportStylistName }}</span></li>
        <li><span>Header Format</span><span>:</span><span>{{ details[selectedDetail].headerFormat }}</span></li>
        <li><span>Message Format</span><span>:</span><span>{{ details[selectedDetail].messageFormat }}</span></li>
      </ul>
    </div>
    <div class="tabbody" *ngIf="selectedDetail === 'RC'">
      
      <ul>
        <li><strong>RC Specific Data</strong></li>
        <li><span>Different Key 1</span><span>:</span><span>{{ details[selectedDetail].differentKey1 }}</span></li>
        <li><span>Different Key 2</span><span>:</span><span>{{ details[selectedDetail].differentKey2 }}</span></li>
        <li><span>Another Description</span><span>:</span><span>{{ details[selectedDetail].anotherDescription }}</span></li>
      </ul>
    </div>
    <div class="tabbody" *ngIf="selectedDetail === 'CCC'">
    
      <ul>
        <li><strong>CCC Specific Data</strong></li>
        <li><span>Additional Key 1</span><span>:</span><span>{{ details[selectedDetail].additionalKey1 }}</span></li>
        <li><span>Additional Key 2</span><span>:</span><span>{{ details[selectedDetail].additionalKey2 }}</span></li>
        <li><span>Another Description</span><span>:</span><span>{{ details[selectedDetail].anotherDescription }}</span></li>
      </ul>
      -->
<!-- </div> -->
<!-- </div> -->
</div>