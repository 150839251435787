<!-- src/app/data-display/data-display.component.html -->
<div class="header">
  <h3>Active Subscription</h3>
  <div class="right-action">
    <button mat-mini-fab (click)="refreshPage()">
      <span class="material-symbols-outlined">refresh</span>
    </button>
    <button mat-raised-button color="primary" (click)="onboard()">
      create Subscription
    </button>
  </div>
</div>

<div class="app-control__section">
  <table mat-table [dataSource]="dataSource" class="app-control-table">

    <!-- Created Column -->
    <ng-container matColumnDef="Created">
      <th mat-header-cell *matHeaderCellDef> Created </th>
      <td mat-cell *matCellDef="let element"> {{formatDate(element.Created)}} </td>
    </ng-container>

    <!-- Meid Column -->
    <ng-container matColumnDef="Meid">
      <th mat-header-cell *matHeaderCellDef> Meid </th>
      <td mat-cell *matCellDef="let element"> {{element.Meid}} </td>
    </ng-container>

    <!-- InstanceIds Column -->
    <ng-container matColumnDef="InstanceIds">
      <th mat-header-cell *matHeaderCellDef> InstanceIds </th>
      <td mat-cell *matCellDef="let element"> {{element.InstanceIds?.join(', ')}} </td>
    </ng-container>

    <!-- SubReqOngoing Column -->
    <ng-container matColumnDef="SubReqOngoing">
      <th mat-header-cell *matHeaderCellDef> SubReqOngoing </th>
      <td mat-cell *matCellDef="let element"> {{element.SubReqOngoing}} </td>
    </ng-container>

    <!-- SubDelReqOngoing Column -->
    <ng-container matColumnDef="SubDelReqOngoing">
      <th mat-header-cell *matHeaderCellDef> SubDelReqOngoing </th>
      <td mat-cell *matCellDef="let element"> {{element.SubDelReqOngoing}} </td>
    </ng-container>

    <!-- Action Column -->
    <ng-container matColumnDef="Action">
      <th mat-header-cell *matHeaderCellDef> Delete Subscription </th>
      <td mat-cell *matCellDef="let element"><mat-icon class="delete_container">delete_outline</mat-icon></td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>
