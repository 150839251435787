<h1 class="onboard_subscription" mat-dialog-title>List of subscriptions</h1>
<div mat-dialog-content>
  <table mat-table [dataSource]="dataSource" matSort class="catalog-table catalog_sect" matSortActive="plmn_id"
    matSortDirection="asc">
    <!-- connection_status  -->
    <ng-container matColumnDef="Inventory_name">
      <mat-header-cell *matHeaderCellDef> Inventory name</mat-header-cell>
      <mat-cell *matCellDef="let element" (click)="onClick(element.inventoryName, 'gnbinfo')" class="inventory_pointer1">
        {{ element.inventoryName }}
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </table>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close>Close</button>
</div>
