import { Component, OnInit, ViewChild } from '@angular/core';
import { OnboardSubscriptionComponent } from './onboard-subscription/onboard-subscription.component';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { XappOnboarderService } from '../services/xapp-onboarder/xapp-onboarder.service';
import { DatePipe } from '@angular/common';


export interface DataElement {
  Created: string;
  Meid: string;
  InstanceIds: number[];
  SubReqOngoing: boolean;
  SubDelReqOngoing: boolean;
}

// const ELEMENT_DATA: DataElement[] = [
//   {
//     Created: "2024-06-26 05:20:58.806",
//     Meid: "gnb_734_373_16b8cef1",
//     InstanceIds: [72],
//     SubReqOngoing: false,
//     SubDelReqOngoing: false
//   }
//   // Add more data objects as needed
// ];

@Component({
  selector: 'rd-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss'],
  providers: [DatePipe]
})
export class SubscriptionComponent implements OnInit {
  displayedColumns: string[] = ['Created', 'Meid', 'InstanceIds', 'SubReqOngoing', 'SubDelReqOngoing','Action'];
  dataSource = new MatTableDataSource<any>();

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  darkMode: boolean;
  panelClass: string;
  private instanceKey: string;

  constructor( 
    private dialog: MatDialog,
    private xappOnboarderService: XappOnboarderService,
    private datePipe: DatePipe 
  ) { }


  ngOnInit() {
    this.xappOnboarderService.getSubscriptionData().subscribe(
      (response) => {
        console.log('Data received:', response);  // Log the response
        // let data = JSON.parse(response);
        this.dataSource.data = JSON.parse(response);
        
      },
      (error) => {
        console.error('Error fetching data:', error);
      }
    );
  }

  formatDate(dateString: string): string {
    return this.datePipe.transform(dateString, 'MMM d, y, h:mm:ss a') || '';
  }


  


  refreshPage(): void {
    // Reload the entire page
    window.location.reload();
  }

 
 

 

  onboard(): void {
    if (this.darkMode) {
      this.panelClass = 'dark-theme';
    } else {
      this.panelClass = '';
    }
    const dialogRef = this.dialog.open(OnboardSubscriptionComponent, {
      panelClass: this.panelClass,
      width: '400px',
      maxHeight: '1000px',
      position: {
        top: '10%'
      },
      data: {
        instanceKey: this.instanceKey
      }
    });

    
  }


 
}

